<template>
    <div id="knowledge">
        <div class="iframe-wrapper">
            <div id="sidebar">
                <el-tree accordion ref="tree_ref" :data="leftSidebarList" :props="defaultProps" node-key="id"
                    expand-on-click-node @node-click="handleNodeClick">
                    <!--  -->
                    <!-- :render-content="renderContent" -->

                    <span slot-scope="{ node, data }" class="custom-tree-node"
                        @mouseenter="visibilityChange($event, data, node)">
                        <el-tooltip :content="node.label" class="item" effect="dark" placement="top-start"
                            :disabled="!isShowTooltip">
                            <span>
                                {{ node.label }}
                            </span>
                        </el-tooltip>
                    </span>
                </el-tree>
            </div>
            <div id="resize"></div>
            <div id="right">
                <!-- 添加遮罩层 -->
                <iframe :src="iframeSrc" width="100%" height="100%" frameborder="0" id="iframe" ref="iframe">
                    <!-- scrolling="no" -->
                    <!-- scrolling="no" -->
                </iframe>
            </div>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
import Footer from "../../components/Footer/index.vue";
export default {
    name: "Knowledge",
    components: {
        Footer
    },
    data() {
        return {
            isShowTooltip: false,
            loading: false,
            // iframeSrc:
            //   process.env.NODE_ENV === "development"
            //     ? "http://localhost:8888"
            //     : "http://47.104.68.37:13010/get_started/calc/双碳知识相关/0-0%20省级温室气体清单编制指南（试行）201105.html",
            // iframeSrc: "http://47.104.68.37:13010/get_started/calc/双碳知识相关/0-0%20省级温室气体清单编制指南（试行）201105.html",
            iframeSrc: `http://47.104.68.37:13010/get_started/calc/%E5%85%A8%E7%90%83%E5%85%B1%E8%AF%86/%E4%BA%AC%E9%83%BD%E8%AE%AE%E5%AE%9A%E4%B9%A6.html?id=1`,
            // iframeSrc: `http://localhost:8888/get_started/calc/%E5%8F%8C%E7%A2%B3%E7%9F%A5%E8%AF%86/0-1%20%E5%85%B3%E4%BA%8E%E5%8D%B0%E5%8F%91%E9%A6%96%E6%89%B910%E4%B8%AA%E8%A1%8C%E4%B8%9A%E4%BC%81%E4%B8%9A%E6%B8%A9%E5%AE%A4%E6%B0%94%E4%BD%93%E6%8E%92%E6%94%BE%E6%A0%B8%E7%AE%97%E6%96%B9%E6%B3%95%E4%B8%8E%E6%8A%A5%E5%91%8A%E6%8C%87%E5%8D%97(%E8%AF%95%E8%A1%8C)%E7%9A%84%E9%80%9A%E7%9F%A5(%E5%8F%91%E6%94%B9%E5%8A%9E%E6%B0%94%E5%80%99[2013]2526%E5%8F%B7).html?id=1`,
            showIframe: true,
            frame: null,
            iframeH: "",
            scrollTimeout: null,
            leftSidebarList: [],
            defaultProps: {
                children: "items",
                label: "label",
            },
        };
    },
    created() {
        this.leftSidebar();
    },
    mounted() {
        this.dragControllerDiv();
    },
    activated() {
        console.log("activated, Knowledge");
        // this.setListener();
        let timer = setTimeout(() => {
            this.$nextTick(() => {
                // 必须在页面加载完成之后获取高
                // iframe自适应高
                let ref = document.getElementById("iframe");
                this.iframe = ref instanceof Array ? ref[0] : ref;
                window.addEventListener("message", this.listenCallback);
                // window.addEventListener("scroll", this.scrollCallback);
            });
        }, 100);
    },
    deactivated() {
        // window.removeEventListener("message", this.listenCallback);
    },
    methods: {
        // 动态的检查目录是否超长
        visibilityChange(event, data, node) {
            const ev = event.target;
            const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
            const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
            // const content_weight = this.$refs.tlp.$el.parentNode.clientWidth; // 文本容器宽度(父节点)
            if (ev_weight > content_weight) {
                // 实际宽度 > 可视宽度  文字溢出
                this.isShowTooltip = true;
            } else {
                // 否则为不溢出
                this.isShowTooltip = false;
            }
        },
        changeIframeDivStyle(display) {
            // var iframeDiv = document.getElementsByClassName("iframeDiv");
            // iframeDiv[0].style.display = display;
        },
        //  两个div滑动事件
        dragControllerDiv: function () {
            let resize = document.getElementById("resize");
            let left = document.getElementById("sidebar");
            let right = document.getElementById("right");
            let box = document.getElementById("knowledge");

            resize.onmousedown = function (e) {
                let startX = e.clientX;
                resize.left = resize.offsetLeft;
                var iframeDiv = document.getElementsByClassName("iframeDiv");
                iframeDiv[0].style.display = "block";
                document.onmousemove = function (e) {
                    let endX = e.clientX;
                    let moveLen = resize.left + (endX - startX);
                    let maxT = box.clientWidth - resize.offsetWidth;
                    if (moveLen < 150) moveLen = 360;
                    if (moveLen > maxT - 800) moveLen = maxT - 800;
                    resize.style.left = moveLen;
                    left.style.width = moveLen + "px";
                    // right.style.width = box.clientWidth - moveLen - 5 + "px";
                };
                document.onmouseup = function () {
                    var iframeDiv = document.getElementsByClassName("iframeDiv");
                    iframeDiv[0].style.display = "none";
                    document.onmousemove = null;
                    document.onmouseup = null;
                    resize.releaseCapture && resize.releaseCapture();
                };
                resize.setCapture && resize.setCapture();
                return false;
            };
        },
        // 目录的点击事件
        handleNodeClick(data, node) {
            console.log(data);
            if (data.hasOwnProperty("file")) {
                this.iframeSrc = `${data.file}.html?id=1`;
            }
            console.log(node);
            // if (node.expanded) {
            //   //展开
            //   let other_nodes = this.$refs.tree_ref.data.filter(
            //     (item) => item.label !== data.label
            //   );
            //   other_nodes.forEach((item) => {
            //     this.$refs.tree_ref.store.nodesMap[item.id].expanded = false;
            //   });
            // }
        },
        // 左边的目录
        leftSidebar() {
            this.api
                .postFormAPISM({}, "/knowledge/getknowledge")
                .then((res) => {
                    console.log(res, "res");
                    let id = 1;
                    function fn(data, level) {
                        for (let i = 0; i < data.length; i++) {
                            data[i] = {
                                id: level + "" + i,
                                ...data[i],
                                label: data[i].label.replace(/.md$/, ""),
                                items:
                                    data[i].items instanceof Array && data[i].items.length
                                        ? fn(data[i].items, level + 1)
                                        : null,
                            };
                        }
                        return data;
                    }
                    this.leftSidebarList = fn(res.data.data, 0);
                    // console.log(this.leftSidebarList, "this.leftSidebarList");
                })
                .catch((error) => {
                    console.log("添加错误", error);
                });
        },
        listenCallback(event) {
            let type = event.data.type;
            if (type === "onload") {
                console.log("parent, message, onload");
                this.iframe.contentWindow.postMessage(
                    {
                        type: "getHeight",
                    },
                    this.iframeSrc
                );
            }
            if (type === "setHeight") {
                console.log("parent, message, setHeight", event.data);
                this.iframeH = event.data.scrollHeight;
            }
            if (type === "contextmenu") {
                console.log("contextmenu", event.data.file);
                let text = this.$router.resolve({
                    path: "/codemirror",
                    query: {
                        file: event.data.file,
                    },
                });
                console.log("text", text);
                // 打开一个新的页面
                window.open(text.href, "_blank");
                return;
            }
        },
        scrollCallback() {
            // let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            // // console.log('scrollCallback', this.iframe.contentWindow);
            // this.iframe.contentWindow.postMessage(
            //     {
            //         type: 'scroll',
            //         scrollTop: scrollTop
            //     },
            //     this.iframeSrc
            // )
            this.scrollTimeout && window.clearTimeout(this.scrollTimeout);
            this.scrollTimeout = setTimeout(() => {
                let scrollTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                // console.log('scrollCallback', this.iframe.contentWindow);
                this.iframe.contentWindow.postMessage(
                    {
                        type: "scroll",
                        scrollTop: scrollTop,
                    },
                    this.iframeSrc
                );
            }, 200);
        },
        setIframeHeight() {
            // let iframe = this.$refs.iframe.contentWindow;
            // let article_ref = iframe.document.getElementById('article');
            // let sidebar_ref = iframe.document.getElementById('sidebar');
            // // let
            // // document.getElementById document.getElementsByClassName
            // console.log(document.getElementById('basicLayout'));
            // if (!article_ref){
            //     //文章首页导航不存在，高度取导航栏的
            //     console.log('!article_ref');
            //     console.log(iframe.document.documentElement.scrollHeight || iframe.contentWindow.document.body.scrollHeight);
            //     console.log('sidebar_ref', sidebar_ref);
            // } else {
            //     //文章首页导航不存在，高度取文章内容和导航栏的最高值
            // }
        },
        handleScrollToTop() {
            console.log("handleScrollToTop");
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        },
    },
};
</script>
<style lang="less">
@import "./index.less";

#sidebar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    -webkit-box-shadow: inset 20px 20px 0 rgba(0, 0, 0, 0.1);
}

div,
span {
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.iframeDiv {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1111;
    filter: alpha(opacity=0);
    opacity: 0;
    background: transparent;
    margin-top: 30px;
    /*display: none;*/
}

#knowledge {
    flex-grow: 1;
    // width: 1200px;
    // width: 100%;
    // margin: 0 auto;
    //   margin: 0 60px;
    // padding: 0 60px;
    float: left;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;

    &>.iframe-wrapper {
        flex-grow: 1;
        display: flex;
        // justify-content : space-around;
        // font-size: 100px;
        // flex-flow: column nowrap;

        &>#sidebar {
            // border-right: 1px solid;
            padding: 8px;
            width: 400px;
            height: 900px;
            overflow-y: scroll;
            // 滚动条整体部分

            &::-webkit-scrollbar {
                width: 10px;
                height: 6px;
                // background-color: #ededed;
            }

            // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
            &::-webkit-scrollbar-button {
                display: none;
            }

            // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
            &::-webkit-scrollbar-thumb {
                background: rgba(144, 147, 153, 0.3);
                cursor: pointer;
                border-radius: 15px;
            }

            // 边角，即两个滚动条的交汇处
            &::-webkit-scrollbar-corner {
                display: none;
            }

            // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
            &::-webkit-resizer {
                display: none;
            }

            &>.el-tree {

                // color: red;
                & .el-tree-node {
                    & .el-tree-node__content {
                        height: 40px;
                        padding: 10px;

                        & .custom-tree-node {
                            & .el-tooltip {
                                margin-left: 5px;
                            }
                        }

                        &:hover {
                            background-color: #d8d8d8 !important;
                            border-radius: 5px;
                        }

                        &>.el-tree-node__expand-icon {
                            &::before {
                                display: none;
                            }

                            // 折叠
                            background-size: 16px;
                            background-repeat: no-repeat;
                            background-position: center;
                            transform: unset;

                            &:not(.is-leaf) {
                                width: 16px;
                                height: 16px;
                                background-image: url("../../assets/images/indicator.svg");

                                &.expanded {
                                    // 展开
                                    background-image: url("../../assets/images/off.svg");
                                }
                            }
                        }

                        &>.el-tree-node__label {
                            padding: 0 10px;
                        }
                    }

                    &.is-current {
                        &>.el-tree-node__content {
                            background-color: #f1f1f1;
                            color: #4caf7d;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }

        &>#resize {
            position: relative;
            width: 2px;
            // height: 100%;

            // cursor: w-resize;
            float: left;
            background-color: #d0d0d0 !important;

            &:hover {
                // width: 5px;
                // transition: 0.5s;
            }
        }

        &>#right {
            width: 800px;
            position: relative;
            flex-grow: 1;
            overflow: hidden;
            // float: right;
            // width: 80%;
            margin: 0;
        }
    }
}
</style>
